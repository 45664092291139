import * as React from 'react';
import SocialProfile from '../../components/social-profile/social-profile';
import {
  IoLogoTwitter,
  IoLogoGithub,
  IoLogoNodejs,
  IoLogoAndroid,
} from 'react-icons/io';
import {
  AboutWrapper,
  AboutDetails,
  SocialProfiles,
} from './style';

const SocialLinks = [
  {
    icon: <IoLogoGithub />,
    url: "https://github.com/danielpdev",
    tooltip: "Github",
  },
  {
    icon: <IoLogoNodejs />,
    url: "https://codesandbox.io/u/danielpdev",
    tooltip: "CodeSandbox",
  },
  {
    icon: <IoLogoTwitter />,
    url: "https://twitter.com/danielpdev",
    tooltip: "Twitter",
  },
  {
    icon: <IoLogoNodejs />,
    url: "https://stackblitz.com/@danielpdev",
    tooltip: "StackBlitz",
  },
  {
    icon: <IoLogoAndroid />,
    url: "https://play.google.com/store/apps/dev?id=5275398609085533875",
    tooltip: "PlayStore",
  },
];

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = () => {

  return (
    <AboutWrapper>
      <AboutDetails>
      <h2>Hey there, what’s up?</h2>
      <p>
        My name is Daniel Popa and I'm currently working as a FullStack JS Web Developer.
      </p>
        <ul>
          <li>
            In 2011 started my bachelor degree in Computer Science at West University of Timisoara.
          </li>
          <li>
            In 2013 had my first job as a developer writing a video application using Universal Windows App with C# and XAML used mainly on Windows Tablets
          </li>
          <li>
            I've moved to developing apps using C++ with Qt Framework for Automotive Industry and also completed my bachelor degree in Computer Science.
          </li>
          <li>
            The next move was Web development using Ruby on Rails for a small startup
          </li>
          <li>
          Currently working as a fullstack JS web developer using MEAN stack.
          </li>
        </ul>
        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  );
};

export default About;
