import { PageProps } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import About from '../containers/about';

const AboutPage: React.FunctionComponent<PageProps> = () => {
  return (
    <Layout>
      <SEO
        title="About Us"
        description="danielpdev.io is a beautiful blog for Daniel Popa developer which contains tutorials and portfolio items ranging from multiple technologies"
      />

      <About />
    </Layout>
  );
};

export default AboutPage;
